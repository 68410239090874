// Kind of like `memoize(...)` except it doesn't check parameter equality.
var callOnce = function callOnce(fn) {
  var cache = {
    primed: false,
    value: null
  };

  var cachingLayer = function cachingLayer() {
    if (!cache.primed) {
      cache.value = fn.apply(void 0, arguments);
      cache.primed = true;
    }

    return cache.value;
  };

  return cachingLayer;
};

export default callOnce;