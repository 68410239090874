/**
 * `ReducerType` represents a different mode of the same action, such as
 * failed actions or optimistic types.
 * @private
 */
var ReducerType;

(function (ReducerType) {
  ReducerType["Optimistic"] = "optimistic";
  ReducerType["Success"] = "success";
  ReducerType["Error"] = "error";
})(ReducerType || (ReducerType = {}));

export default ReducerType;