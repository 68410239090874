import { produce } from 'immer';
import ReducerType from '../constants/reducer-type';
export default function mapActionsToReducers(reducerDefinitions) {
  var mapping = new Map();
  reducerDefinitions.forEach(function (_ref) {
    var actionType = _ref.actionType,
        reducer = _ref.reducer,
        reducerType = _ref.reducerType;

    if (!mapping.has(actionType)) {
      mapping.set(actionType, {
        success: [],
        error: [],
        optimistic: []
      });
    } // eslint-disable-next-line @typescript-eslint/no-non-null-assertion


    var handlers = mapping.get(actionType);
    var immutableReducer = produce(reducer);

    if (reducerType === ReducerType.Success) {
      handlers.success.push(immutableReducer);
    } else if (reducerType === ReducerType.Error) {
      handlers.error.push(immutableReducer);
    } else if (reducerType === ReducerType.Optimistic) {
      handlers.optimistic.push(immutableReducer);
    }
  });
  return mapping;
}