import assert from '../utils/assert';
import getActionType from './get-action-type';
import ReducerType from '../constants/reducer-type';

var assertValidArguments = function assertValidArguments(actionCreator, reducer) {
  assert(actionCreator !== undefined, 'handleAction(...) expects an action creator (given undefined).');
  assert(typeof reducer === 'function', 'handleAction(...) expects a reducer function.');
};

var handleAction = function handleAction(actionCreator, reducer) {
  assertValidArguments(actionCreator, reducer);
  return {
    actionType: getActionType(actionCreator),
    reducerType: ReducerType.Success,
    reducer: reducer
  };
};

handleAction.error = function (actionCreator, reducer) {
  assertValidArguments(actionCreator, reducer);
  return {
    actionType: getActionType(actionCreator),
    reducerType: ReducerType.Error,
    reducer: reducer
  };
};

handleAction.optimistic = function (actionCreator, reducer) {
  assertValidArguments(actionCreator, reducer);
  return {
    actionType: getActionType(actionCreator),
    reducerType: ReducerType.Optimistic,
    reducer: reducer
  };
};

export default handleAction;