import _defineProperty from "@babel/runtime/helpers/defineProperty";
import Phase from '../constants/phase';

/**
 * ADVANCED: Create plain actions without binding effects. Allows explicitly
 * dispatching actions while preserving type signatures in reducers.
 * @param actionType The redux action type.
 *
 * @example
 * createAction.factory<SuccessType, OptimisticType>('load')
 */
export default function createActionFactory(actionType) {
  var _ref;

  return _ref = {}, _defineProperty(_ref, ACTION_TYPE, actionType), _defineProperty(_ref, "success", function success(payload) {
    return {
      type: actionType,
      payload: payload
    };
  }), _defineProperty(_ref, "failure", function failure(error) {
    return {
      type: actionType,
      error: true,
      payload: error
    };
  }), _defineProperty(_ref, "optimistic", function optimistic(payload) {
    return {
      type: actionType,
      payload: payload,
      meta: {
        phase: Phase.Optimistic
      }
    };
  }), _ref;
}
export var ACTION_TYPE = Symbol('retreon:action-type');