import handleAction from './handle-action';
import mapActionsToReducers from './map-actions-to-reducers';
import callOnce from '../utils/call-once';
import assert from '../utils/assert';
import { isActionFailure, isOptimisticAction } from '../utils/action-variant';
export default function createReducer(initialState, reducerFactory) {
  assert(typeof reducerFactory === 'function', 'createReducer(...) expects a function.'); // Wait until the first time the reducer is called before constructing the
  // reducer map. It's a semi-common pattern to share constants between
  // actions and reducers, which means the two files may form a circular
  // dependency. By the time `createReducer(...)` is called, those actions
  // could be undefined.

  var lazilyCreateActionMap = callOnce(function () {
    var reducers = reducerFactory(handleAction);
    assert(typeof reducers !== 'undefined', "createReducer(...) expects a list of action handlers (got undefined).");
    var actionMap = mapActionsToReducers(reducers);
    return actionMap;
  });

  var reducer = function reducer() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    var action = arguments.length > 1 ? arguments[1] : undefined;
    var actionMap = lazilyCreateActionMap();
    var handlers = actionMap.get(action.type);
    if (!handlers) return state;
    var reducersForActionType = isActionFailure(action) ? handlers.error : isOptimisticAction(action) ? handlers.optimistic : handlers.success;
    return reducersForActionType.reduce(function (state, reducer) {
      return reducer(state, action.payload);
    }, state);
  };

  return reducer;
} // `createReducer` has a circular type which makes the definition somewhat
// tedious. We can't define `handleAction` without knowing how you're calling
// `createReducer`, and we can't define how to call `createReducer` without
// knowing the type for `handleAction`. The state and reducer types are
// codependent.
//
// Lifting `handleAction` to a parametrized interface allows us to express the
// type for the reducer parameter and in turn, `createReducer`.