var f = Object.defineProperty;
var p = (e, t, n) => t in e ? f(e, t, { enumerable: !0, configurable: !0, writable: !0, value: n }) : e[t] = n;
var r = (e, t, n) => (p(e, typeof t != "symbol" ? t + "" : t, n), n);
function o() {
  return typeof navigator < "u" && !!navigator.mediaDevices;
}
function c() {
  if (!o())
    throw new Error("The media devices API isn't supported here.");
  return navigator.mediaDevices;
}
async function g() {
  return (await c().enumerateDevices()).filter(h).map(m);
}
function h(e) {
  return e.deviceId !== "default";
}
function m(e) {
  return {
    label: e.label || null,
    kind: e.kind,
    deviceId: e.deviceId || null,
    groupId: e.groupId || null
  };
}
var D = /* @__PURE__ */ ((e) => (e.VideoInput = "videoinput", e.AudioInput = "audioinput", e.AudioOutput = "audiooutput", e))(D || {});
async function I(e) {
  return c().getUserMedia(e);
}
class y {
  constructor() {
    r(this, "_knownDevices", []);
    r(this, "_gainedScreenAccessOnce", !1);
    /**
     * Specifies a function to be called whenever the list of available devices
     * changes.
     *
     * Note: this is different from the native event. It passes the changeset
     * and full list of devices as a parameter.
     */
    r(this, "ondevicechange", null);
    /**
     * Request a live media stream from audio and/or video devices. Streams are
     * configurable through constraints.
     * See: https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
     */
    r(this, "getUserMedia", async (t) => {
      const n = await I(t);
      return this.enumerateDevices(), n;
    });
    /**
     * Ask the user to share their screen. Resolves with a media stream carrying
     * video, and potentially audio from the application window.
     * See: https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getDisplayMedia
     */
    r(this, "getDisplayMedia", async (t) => {
      const n = await c().getDisplayMedia(t);
      return this._gainedScreenAccessOnce || (this._gainedScreenAccessOnce = !0, this.enumerateDevices()), n;
    });
    /**
     * Lists every available hardware device, including microphones, cameras,
     * and speakers (depending on browser support). May contain redacted
     * information depending on application permissions.
     */
    r(this, "enumerateDevices", async () => {
      const t = await g();
      return this._checkForDeviceChanges(t), t;
    });
    /**
     * Returns an object containing every media constraint supported by the
     * browser.
     * See: https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getSupportedConstraints
     */
    r(this, "getSupportedConstraints", () => c().getSupportedConstraints());
    o() && c().addEventListener("devicechange", () => this.ondevicechange ? this.enumerateDevices() : Promise.resolve());
  }
  _checkForDeviceChanges(t) {
    var a;
    const n = this._knownDevices;
    this._knownDevices = t;
    const i = this._calculateDeviceDiff(
      t,
      n
    );
    i.length && ((a = this.ondevicechange) == null || a.call(this, { changes: i, devices: t }));
  }
  /**
   * Note: The device enumeration API may return null values for device IDs
   * and labels. To avoid creating erroneous "Device Added" notifications,
   * a best effort should be made to detect when devices are identical.
   *
   * Order is significant. Preferred devices are listed first, which helps
   * correlate devices from permissioned requests with unpermissioned
   * requests.
   */
  _calculateDeviceDiff(t, n) {
    const i = n.slice(), a = [], l = t.filter((s) => {
      const d = i.findIndex((u) => _(s, u));
      if (d > -1) {
        const [u] = i.splice(d, 1);
        if (s.label !== u.label) {
          const v = {
            type: "update",
            newInfo: s,
            oldInfo: u
          };
          a.push(v);
        }
      }
      return d === -1;
    });
    return [
      ...a,
      // A device was just removed.
      ...i.map((s) => ({ type: "remove", device: s })),
      // A device was just plugged in.
      ...l.map((s) => ({ type: "add", device: s }))
    ];
  }
}
function _(e, t) {
  if (t.deviceId)
    return e.deviceId === t.deviceId;
  function n(i) {
    return `${i.kind}:${i.groupId}`;
  }
  return n(e) === n(t);
}
var k = /* @__PURE__ */ ((e) => (e.Add = "add", e.Remove = "remove", e.Update = "update", e))(k || {});
const M = new y();
export {
  D as DeviceKind,
  k as OperationType,
  M as default,
  o as supportsMediaDevices
};
