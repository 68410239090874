import Phase from '../constants/phase';
/**
 * A set of utilities to determine the FSA "variant" (error, success,
 * optimistic) while making sure TypeScript stays up to speed.
 */

export var isActionSuccess = function isActionSuccess(action) {
  var error = action.error,
      meta = action.meta;
  return error !== true && (!meta || meta.phase !== Phase.Optimistic);
};
export var isActionFailure = function isActionFailure(action) {
  return action.error === true;
};
export var isOptimisticAction = function isOptimisticAction(action) {
  return Boolean(action.error !== true && action.meta && action.meta.phase === Phase.Optimistic);
};