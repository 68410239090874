// `createAction(...)` coerces to its action constant. This utility function
// grabs that constant.
import { ACTION_TYPE } from '../actions/action-factory';
import assert from '../utils/assert';
export default function getActionType(actionCreator) {
  if (typeof actionCreator === 'string') return actionCreator;

  if (ACTION_TYPE in Object(actionCreator)) {
    return actionCreator[ACTION_TYPE];
  }

  assert(typeof actionCreator === 'function', 'Expected an action creator or action type.');
  return String(actionCreator);
}